import FinanceApi from '@/services/FinanceApi';

const storeExplanation = async (id, explanation) => {
    return FinanceApi.put('/payment/plans/' + id + '/explanation', {'explanation': explanation});
}

const storeCash = async (id, is_cash) => {
    return FinanceApi.put('/payment/plans/' + id + '/is_cash', {'is_cash': is_cash});
}

const create = async (data) => {
    return FinanceApi.post('/payment/plans/create', data);
}

const pilotageFee = async (data) => {
    return FinanceApi.post('/payment/plans/pilotage-fee', data);
}

const deletePaymentPlan = async (id) => {
    return FinanceApi.delete('/payment/plans/' + id);
}

const credit = async (data) => {
    return FinanceApi.post('/payment/plans/credit', data);
}

const getCreditsByAcademicYear = async (studentProgramId, academicYear) => {
    return FinanceApi.get('/payment/plans/credits-by-academic-year/'+studentProgramId+'/'+academicYear);
}

const course = async (data) => {
    return FinanceApi.post('/payment/plans/course', data);
}

const graduate = async (data) => {
    return FinanceApi.post('/payment/plans/graduate', data);
}

const report = async (formData) => {
    return FinanceApi.post('/payment/plans/report', formData);
}

const patch = async (id, data) => {
    return FinanceApi.patch('/payment/plans/'+id, data);
}

const updateDueDate = async (data) => {
    return FinanceApi.patch('/payment/plans/due-date', data);
}

const paymentApprovalJob = async (data) => {
    return FinanceApi.post('/payment/plans/approval-job', data);
}

const move = async (id, data) => {
    return FinanceApi.patch('/payment/plans/'+id+'/move', data);
}

const find = async (config) => {
    return FinanceApi.get('/payment/plans/find', config);
}

const createLeaveOfAbsence = async (data) => {
    return FinanceApi.post('/payment/plans/leave-of-absence', data);
}

const calculateRefund = async (id, data) => {
    return FinanceApi.post('/payment/plans/'+id+'/calculate-refund', data);
}

export default {
    storeExplanation,
    storeCash,
    create,
    deletePaymentPlan,
    credit,
    graduate,
    report,
    course,
    patch,
    updateDueDate,
    paymentApprovalJob,
    pilotageFee,
    move,
    find,
    createLeaveOfAbsence,
    calculateRefund,
    getCreditsByAcademicYear
}
